import React from "react";
const Page404 = () => {
  return (
    <div className="acessDenied">
      <div>
        <span style={{ fontSize: "55px" }}>404</span>
        <br />
        <br />
        Page Still In Development!
      </div>
    </div>
  );
};

export default Page404;
